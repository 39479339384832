import React, { useState, useContext, useEffect, useCallback, useReducer } from 'react';
import _ from 'lodash';
import { RouteComponentProps, useParams } from 'react-router-dom';
import ZoomVideo, { ConnectionState, ReconnectReason } from '@zoom/videosdk';
import ZoomContext from '../../context/zoom-context';
import MediaContext from '../../context/media-context';
import { Button, Input, Modal, message } from 'antd';
import { isAndroidBrowser } from '../../utils/platform';
import { ZoomClient } from '../../index-types';
import SupportGalleryViewContext from '../../context/support-gallery-view-context';
import VideoContainer1 from '../video/video';
import VideoContainer2 from '../video/video-non-sab';
import VideoContainer3 from '../video/video-single';
import { ParameterTypes } from '../../utils/ParameterTypes';
import LoadingLayer from '../../component/loading-layer';
import { TranslationOutlined } from '@ant-design/icons';
import { generateMeetingArgs } from '../../utils/util';
import LiveTranscriptionContext from '../../context/live-transcription';
import RecordingContext from '../../context/recording-context';
import SessionContext from '../../context/session-context';

interface VideoMainProps extends RouteComponentProps {
    meetingArgs: {
      sdkKey: string;
      topic: string;
      signature: string;
      name: string;
      password?: string;
      webEndpoint?: string;
      enforceGalleryView?: string;
    };
    galleryViewWithoutSAB: boolean;
    zmClient: ZoomClient;
    dispatch: React.Dispatch<any>;
}

const VideoMain: React.FunctionComponent<VideoMainProps> = (props) => {
    const { meetingArgs: { sdkKey, topic, signature, name, password, webEndpoint: webEndpointArg, enforceGalleryView }, galleryViewWithoutSAB, zmClient } = props;

    const { history, dispatch } = props;
    const [isFailover, setIsFailover] = useState<boolean>(false);
    const [status, setStatus] = useState<string>('closed');
    const { sessionNamePara, sessionPasswordPara, guestName } = useParams<ParameterTypes>();
    const {setMediaStream} = useContext(MediaContext);
    const {setRecordingClient} = useContext(RecordingContext);
    const {setLiveTranscriptionClient} = useContext(LiveTranscriptionContext);
    const {zoomClient, setZoomClient} = useContext(ZoomContext);
    const {setSessionName, setSessionPassword} = useContext(SessionContext);

    const {isSupportGalleryView, setIsSupportGalleryView} = useContext(SupportGalleryViewContext);
    const [showMeeting, setShowMeeting] = useState(false);
    const [showHostView, setShowHostView] = useState(false);
    const [gName, setGName] = useState("");
    const [loading, setIsLoading] = useState(true);
    const [btnEnabled, setBtnEnabled] = useState(true);
    const [isModalOpen, setIsModalOpen] = useState(false);

    let webEndpoint: any;
    
    if (!webEndpoint) {
        webEndpoint = window?.webEndpoint ?? 'zoom.us';
    }

    useEffect(() => {
      setZoomClient(zmClient);

      if(!sessionNamePara || !sessionPasswordPara) {
        setShowMeeting(false);
        setIsLoading(false);
      } else {
        setSessionName(sessionNamePara);
        setSessionPassword(sessionPasswordPara);

        if(guestName) {
          connectToMeeting(guestName);
        } else {
            setShowHostView(true);
            setIsLoading(false);
        }
      }
    }, [sdkKey, signature, zoomClient, webEndpoint, galleryViewWithoutSAB]);

    const connectToMeeting = (personName: string) => {
      const init = async () => {
        if(!zoomClient || !zoomClient?.getCurrentUserInfo()) {
          await zoomClient?.init('en-US', `${window.location.origin}/lib`, {
            webEndpoint,
            enforceMultipleVideos: galleryViewWithoutSAB,
            stayAwake: true
          });

          let meetingArgsNew = generateMeetingArgs(sessionNamePara, sessionPasswordPara, personName, 1);

          await zoomClient?.join(sessionNamePara, meetingArgsNew.signature, personName, sessionPasswordPara).catch((e) => {
            console.log(e);
          });
        }

        console.log('Joining the session...');
  
        try {            
          if(zoomClient) {
            const stream = zoomClient?.getMediaStream();
            setMediaStream(stream);
            setIsSupportGalleryView(stream.isSupportMultipleVideos() && !isAndroidBrowser());
            const recordingClient = zoomClient?.getRecordingClient();
            const ltClient = zoomClient?.getLiveTranscriptionClient();

            setRecordingClient(recordingClient);
            setLiveTranscriptionClient(ltClient);

            setShowHostView(false);
            setShowMeeting(true);
            setIsLoading(false);
          }
        } catch (e: any) {
          console.log('Error');
          console.log(e);
          message.error(e.reason);
        }
      };
  
      init();

      return () => {
        ZoomVideo.destroyClient();
      };
    }

    const joinMeeting = () => {
      if(showHostView && gName) {
        setBtnEnabled(false);

        connectToMeeting(gName);

        history.push('/video/'+sessionNamePara+'/pwd/'+sessionPasswordPara+'/guest/'+gName);
      } else {
        message.error("Please enter guest name!");
      }
    }

    const onConnectionChange = useCallback(
      (payload) => {
        if (payload.state === ConnectionState.Reconnecting) {
          setIsLoading(true);
          setIsFailover(true);
          setStatus('connecting');
          const { reason, subsessionName } = payload;
          if (reason === ReconnectReason.Failover) {
            console.log('Session Disconnected,Try to reconnect');
          } else if (reason === ReconnectReason.JoinSubsession || reason === ReconnectReason.MoveToSubsession) {
            console.log(`Joining ${subsessionName}...`);
          } else if (reason === ReconnectReason.BackToMainSession) {
            console.log('Returning to Main Session...');
          }
        } else if (payload.state === ConnectionState.Connected) {
          setStatus('connected');
          if (isFailover) {
            setIsLoading(false);
          }
          window.zmClient = zmClient;
          window.mediaStream = zmClient.getMediaStream();
  
          console.log('getSessionInfo', zmClient.getSessionInfo());
        } else if (payload.state === ConnectionState.Closed) {
          setStatus('closed');
          dispatch({ type: 'reset-media' });
          if (payload.reason === 'ended by host') {
            setIsModalOpen(true);            
          }
        }
      },
      [isFailover, zmClient]
    );
  
    const onMediaSDKChange = useCallback((payload) => {
      const { action, type, result } = payload;
      dispatch({ type: `${type}-${action}`, payload: result === 'success' });
    }, []);
  
    const onDialoutChange = useCallback((payload) => {
      console.log('onDialoutChange', payload);
    }, []);
  
    const onAudioMerged = useCallback((payload) => {
      console.log('onAudioMerged', payload);
    }, []);
    
    useEffect(() => {
      zmClient.on('connection-change', onConnectionChange);
      zmClient.on('media-sdk-change', onMediaSDKChange);
      zmClient.on('dialout-state-change', onDialoutChange);
      zmClient.on('merged-audio', onAudioMerged);
      
      return () => {
        zmClient.off('connection-change', onConnectionChange);
        zmClient.off('media-sdk-change', onMediaSDKChange);
        zmClient.off('dialout-state-change', onDialoutChange);
        zmClient.off('merged-audio', onAudioMerged);
      };
    }, [zmClient, onConnectionChange, onMediaSDKChange, onDialoutChange, onAudioMerged]);

    return (
        <>
          {loading ? <LoadingLayer content="Loading..." /> : 
           <>
             {showMeeting && !showHostView &&
              (
                  isSupportGalleryView ? 
                      <VideoContainer1></VideoContainer1>

                      : galleryViewWithoutSAB ? <VideoContainer2></VideoContainer2> 

                      :

                      <VideoContainer3></VideoContainer3>

              )
              }

              {!showMeeting && !showHostView && 
                <div className='invalid-meeting'>
                  <>Invalid Meeting ID</>
                </div>
              }

              {showHostView && 
                <div className='join-meeting-parent'>
                    <div className='join-meeting'>
                        <h3>Guest Name</h3>

                        <Input placeholder="Guest Name" value={gName} onChange={event => { setGName(event?.target.value); }} />

                        <br/>

                        <Button type="primary" shape="round" icon={<TranslationOutlined />} size='large' onClick={joinMeeting} disabled={!btnEnabled}>Join Session</Button>
                    </div>
                </div>
            }
           </>
          }

        <Modal title="Basic Modal" open={isModalOpen} onOk={() => {
          history.push('/');
        }}>
          <h4>Meeting Ended!</h4>
          <p>This meeting has been ended by host</p>
        </Modal>
        </>
    );
};

export default VideoMain;