import React, { useState, useReducer, useMemo } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import 'antd/dist/antd.min.css';
import produce from 'immer';
import Home from './feature/home/home';
import Preview from './feature/preview/preview';
import ZoomContext from './context/zoom-context';
import ZoomMediaContext from './context/media-context';
import LiveTranscriptionContext from './context/live-transcription';
import RecordingContext from './context/recording-context';
import Subsession from './feature/subsession/subsession';
import {
  ChatClient,
  CommandChannelClient,
  LiveTranscriptionClient,
  MediaStream,
  RecordingClient,
  SubsessionClient,
  ZoomClient
} from './index-types';
import './App.css';
import SubsessionContext from './context/subsession-context';
import SupportGalleryViewContext from './context/support-gallery-view-context';
import VideoMain from './feature/home/video-main';
import SessionContext from './context/session-context';
interface AppProps {
  meetingArgs: {
    sdkKey: string;
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
  };
  zmClient: ZoomClient;
}

declare global {
  interface Window {
    webEndpoint: string | undefined;
    zmClient: any | undefined;
    mediaStream: any | undefined;
    crossOriginIsolated: boolean;
    ltClient: any | undefined;
  }
}

const mediaShape = {
  audio: {
    encode: false,
    decode: false
  },
  video: {
    encode: false,
    decode: false
  },
  share: {
    encode: false,
    decode: false
  }
};

const mediaReducer = produce((draft, action) => {
  switch (action.type) {
    case 'audio-encode': {
      draft.audio.encode = action.payload;
      break;
    }
    case 'audio-decode': {
      draft.audio.decode = action.payload;
      break;
    }
    case 'video-encode': {
      draft.video.encode = action.payload;
      break;
    }
    case 'video-decode': {
      draft.video.decode = action.payload;
      break;
    }
    case 'share-encode': {
      draft.share.encode = action.payload;
      break;
    }
    case 'share-decode': {
      draft.share.decode = action.payload;
      break;
    }
    case 'reset-media': {
      Object.assign(draft, { ...mediaShape });
      break;
    }
    default:
      break;
  }
}, mediaShape);

function App(props: AppProps) {
  const { meetingArgs: { sdkKey, topic, signature, name, password, webEndpoint: webEndpointArg, enforceGalleryView }, zmClient } = props;
  const [mediaState, dispatch] = useReducer(mediaReducer, mediaShape);
  const [mediaStream, setMediaStream] = useState<MediaStream | null>(null);
  const [recordingClient, setRecordingClient] = useState<RecordingClient | null>(null);
  const [liveTranscriptionClient, setLiveTranscriptionClient] = useState<LiveTranscriptionClient | null>(null);
  const [isSupportGalleryView, setIsSupportGalleryView] = useState<boolean>(true);
  const [zoomClient, setZoomClient] = useState<ZoomClient>();
  const [sessionName, setSessionName] = useState<string>();
  const [sessionPassword, setSessionPassword] = useState<string>();

  let webEndpoint: any;

  if (webEndpointArg) {
    webEndpoint = webEndpointArg;
  } else {
    webEndpoint = window?.webEndpoint ?? 'zoom.us';
  }

  const mediaContext = useMemo(() => ({ ...mediaState, mediaStream, setMediaStream }), [mediaState, mediaStream, setMediaStream]);

  const galleryViewWithoutSAB = Number(enforceGalleryView) === 1 && !window.crossOriginIsolated;
  
  
  return (
    <div className="App">
      <ZoomContext.Provider value={{zoomClient, setZoomClient}}>
        <SessionContext.Provider value={{sessionName, setSessionName, sessionPassword, setSessionPassword}}>
          <ZoomMediaContext.Provider value={mediaContext}>
            <SupportGalleryViewContext.Provider value={{isSupportGalleryView, setIsSupportGalleryView}}>
              <RecordingContext.Provider value={{recordingClient, setRecordingClient}}>
                  <LiveTranscriptionContext.Provider value={{liveTranscriptionClient, setLiveTranscriptionClient}}>
                    <Router>
                      <Switch>
                        <Route
                          path="/"
                          render={(props) => (
                            <Home {...props} meetingArgs={{ sdkKey, topic, signature, name, password, webEndpoint: webEndpointArg, enforceGalleryView }} galleryViewWithoutSAB={galleryViewWithoutSAB} zmClient={zmClient}/>
                          )}
                          exact
                        />
                        <Route
                          path="/video/:sessionNamePara?/pwd/:sessionPasswordPara?/guest/:guestName?"
                          render={(props) => (
                            <VideoMain {...props} dispatch={dispatch} meetingArgs={{ sdkKey, topic, signature, name, password, webEndpoint: webEndpointArg, enforceGalleryView }} galleryViewWithoutSAB={galleryViewWithoutSAB} zmClient={zmClient}></VideoMain>
                          )}
                          exact
                        />
                        <Route path="/subsession" component={Subsession} />
                        <Route path="/preview" component={Preview} />
                      </Switch>
                    </Router>
                  </LiveTranscriptionContext.Provider>
              </RecordingContext.Provider>
            </SupportGalleryViewContext.Provider>
          </ZoomMediaContext.Provider>
        </SessionContext.Provider>
      </ZoomContext.Provider>
    </div>
  );
}

export default App;