/* eslint-disable no-restricted-globals */
import React, { useEffect, useContext, useState, useCallback, useReducer, useMemo } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { Card, Button, message, Input } from 'antd';
import { IconFont } from '../../component/icon-font';
import './home.scss';
import { ZoomClient } from '../../index-types';
import MediaContext from '../../context/media-context';
import { isAndroidBrowser } from '../../utils/platform';
import ZoomVideo from '@zoom/videosdk';
import { generateMeetingArgs } from '../../utils/util';
import ZoomContext from '../../context/zoom-context';
import SessionContext from '../../context/session-context';
import SupportGalleryViewContext from '../../context/support-gallery-view-context';
import LiveTranscriptionContext from '../../context/live-transcription';
import { TranslationOutlined } from '@ant-design/icons';
import RecordingContext from '../../context/recording-context';

const { Meta } = Card;
interface HomeProps extends RouteComponentProps {
  meetingArgs: {
    sdkKey: string;
    topic: string;
    signature: string;
    name: string;
    password?: string;
    webEndpoint?: string;
    enforceGalleryView?: string;
  };
  galleryViewWithoutSAB: boolean;
  zmClient: ZoomClient;
}

const Home: React.FunctionComponent<HomeProps> = (props) => {
  const { meetingArgs: { sdkKey, topic, signature, name, password, webEndpoint: webEndpointArg, enforceGalleryView }, galleryViewWithoutSAB, zmClient } = props;

  const { history } = props;
  const {setMediaStream} = useContext(MediaContext);
  const {setIsSupportGalleryView} = useContext(SupportGalleryViewContext);
  const {setRecordingClient} = useContext(RecordingContext);
  const {setLiveTranscriptionClient} = useContext(LiveTranscriptionContext);
  const {zoomClient, setZoomClient} = useContext(ZoomContext);
  const {sessionName, setSessionName, sessionPassword, setSessionPassword} = useContext(SessionContext);

  const [hostName, seHostName] = useState("");
  const [btnEnabled, setBtnEnabled] = useState(true);

  let webEndpoint: any;
  
  if (!webEndpoint) {
    webEndpoint = window?.webEndpoint ?? 'zoom.us';
  }

  useEffect(() => {
    setZoomClient(zmClient);
  }, [sdkKey, signature, zoomClient, webEndpoint, galleryViewWithoutSAB]);
  
  const startSessionAndJoinAsHost = async () => {
    if(!sessionName) {
      message.error("Please enter session name!");
      return false;
    }

    if(!sessionPassword) {
      message.error("Please enter session password!");
      return false;
    }

    if(!hostName) {
      message.error("Please enter host name!");
      return false;
    }

    setBtnEnabled(false);

    await zoomClient?.init('en-US', `${window.location.origin}/lib`, {
      webEndpoint,
      enforceMultipleVideos: galleryViewWithoutSAB,
      stayAwake: true
    });

    try {
      console.log('Joining the session...');

      let meetingArgsNew = generateMeetingArgs(sessionName, sessionPassword, hostName, 1);
      
      await zoomClient?.join(sessionName, meetingArgsNew.signature, hostName, sessionPassword).catch((e) => {
        console.log(e);
      });
      
      if(zoomClient) {
        const stream = zoomClient?.getMediaStream();
        setMediaStream(stream);
        setIsSupportGalleryView(stream.isSupportMultipleVideos() && !isAndroidBrowser());
        const recordingClient = zoomClient?.getRecordingClient();
        const ltClient = zoomClient?.getLiveTranscriptionClient();

        setRecordingClient(recordingClient);
        setLiveTranscriptionClient(ltClient);

        history.push('/video/'+sessionName+'/pwd/'+sessionPassword+'/guest/'+hostName);
      }
    } catch (e: any) {
      // setIsLoading(false);
      console.log('Error');
      console.log(e);
      message.error(e.reason);
    }

    return () => {
      ZoomVideo.destroyClient();
    };
  };


  return (
    <div>
      <div className="nav">
          <a href="/" className="navhome">
              <img src="./logo.svg" alt="Home" />
              <span>Lavni Zoom Video SDK</span>
          </a>
      </div>

      <div className="home">
          <h1>Lavni Zoom Video SDK Demo</h1>
          
          <div className="feature-entry">
              <div className="home-s1">
                  <h2>Session Name :</h2>
                  <Input placeholder="Session Name" value={sessionName} onChange={event => { setSessionName(event?.target.value); }} />

                  <br />

                  <h2>Session Password :</h2>
                  <Input type='password' placeholder="Session Password" value={sessionPassword} onChange={event => { setSessionPassword(event?.target.value); }} />

                  <br />

                  <h2>Host Name :</h2>
                  <Input placeholder="Host Name" value={hostName} onChange={event => { seHostName(event?.target.value); }} />

                  <br />

                  <Button type="primary" shape="round" icon={<TranslationOutlined />} size='large' onClick={startSessionAndJoinAsHost} disabled={!btnEnabled}>Create Session</Button>
              </div>
          </div>
      </div>
    </div>
  );
};
export default Home;
