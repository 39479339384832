import React from 'react';
import { Button, Dropdown, Menu } from 'antd';
import classNames from 'classnames';
import { IconFont } from '../../../component/icon-font';

interface LeaveButtonProps {
  onCopyInvite: () => void;
}

const InviteButton = (props: LeaveButtonProps) => {
  const { onCopyInvite } = props;

  return <Button
    className={classNames('vc-button')}
    icon={<IconFont type="icon-copy" />}
    ghost={true}
    shape="circle"
    size="large"
    onClick={onCopyInvite}
    title="Copy Invite Link"
  />;
};

export { InviteButton };
