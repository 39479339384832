import { getExploreName } from '../utils/platform';

export const devConfig = {
  sdkKey: 'ZZa8yAugmN2MwwqN10m44x258idtDBbtGLTv',
  sdkSecret: '45Puqdo9Dt2XKECKTEfAaehVB7nqjfVNa6QH',
  webEndpoint: 'zoom.us',
  topic: 'EfitoSolutions',
  name: `${getExploreName()}-${Math.floor(Math.random() * 1000)}`,
  password: 'abc123',
  signature: '',
  sessionKey: '',
  userIdentity: '',
  // role = 1 to join as host, 0 to join as attendee. The first user must join as host to start the session
  role: 1
};
